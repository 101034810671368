import { NavLink } from "react-router-dom";
import constant from "../../constant";
import { getStorage } from "../../utils";

function Navigation() {
    const detail = getStorage(constant.key.eventData);
    if (detail === null || detail === undefined) {
        return null;
    }
    const version = getStorage(constant.key.version)
    return (
        <>
            <div className="" style={{ marginBottom: '6rem' }}>
                &nbsp;
            </div>
            <div style={{
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                background: '#fff',
                padding: '1rem 0rem',
                borderTop: '1px solid #eee',
                zIndex: 3
            }}>
                <div>
                    <NavLink activeStyle={{ color: '#2c7be5' }} to={constant.component.eventHome.url} className="btn btn-falcon-default rounded-pill">
                        {/* <i className="fas fa-edit"></i> */}
                        <svg style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                    </NavLink>
                </div>
                <div>
                    <button className="btn btn-link p-0 text-white" onClick={() => alert(version)}>test</button>
                    {/* <NavLink activeStyle={{ color: '#2c7be5' }} to={constant.component.eventDownloadPDF.url} className="btn btn-falcon-default rounded-pill">
                        <i className="fas fa-file-download"></i>
                    </NavLink> */}
                </div>
                <div>
                    <NavLink activeStyle={{ color: '#2c7be5' }} disabled={!detail} to={constant.component.eventReport.url} className="btn btn-falcon-default rounded-pill">
                        {/* <i className="fas fa-chart-pie"></i> */}
                        <svg style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z" /></svg>
                    </NavLink>
                </div>
                {/* <div>
                    <NavLink activeStyle={{ color: '#2c7be5' }} to={constant.component.eventDownloadPDF.url} className="btn btn-falcon-default rounded-pill">
                        <i className="fas fa-file-download"></i>
                    </NavLink>
                </div> */}
            </div>
        </>
    )
}

export default Navigation;