import { PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

import { dynamicSort, getFormattedFloat, getFormattedNumber, getStorage } from '../../utils';
import { useHistory } from "react-router-dom";
import Navigation from '../../component/event/Navigation-2';
import constant from '../../constant';
import PieChart from '../../component/event/PieChart';
import { useEffect, useState } from 'react';
import { addDownloadDetail2 } from '../../action/VisitorDetailAction';
import moment from 'moment';

const LABEL = {
    estimatedEmission: {
        en: 'Estimated Emissions',
        sp: 'Emisiones estimadas'
    },
    downloadCertificate: {
        en: 'Download Certificate',
        sp: 'Descargar Certificado'
    },
    hotel: { en: 'Hotel', sp: 'Hotel' },
    catering: { en: 'Catering', sp: 'Alimento' },
    eventVenue: { en: 'Event Venue', sp: 'Lugar del evento' },
    travel: { en: 'Travel', sp: 'Viajar' },
}

function Report() {
    document.title = LABEL.estimatedEmission.sp;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const detail = getStorage(constant.key.eventData);
    let history = useHistory();

    if (!detail) {
        history.push(constant.component.event2Home.url);
    }

    const ed = JSON.parse(detail.emission_json)
    const total_emission = Math.round(detail.total_emission);

    const categories = [
        // { name: 'Electricity', value: parseFloat(ed.energy), icon: 'fas fa-bolt', color: '#84CC16' },
        // { name: 'Water', value: parseFloat(ed.water), icon: 'fas fa-tint', color: '#06B6D4' },
        // { name: 'Waste', value: parseFloat(ed.waste), icon: 'fas fa-recycle', color: '#10B981' },
        { name: LABEL.catering.sp, value: parseFloat(ed.catering), icon: 'fas fa-utensils', color: '#8B5CF6' },
        // { name: 'Event Material', value: parseFloat(ed.event_material), icon: 'fas fa-coffee', color: '#D946EF' },
        { name: LABEL.travel.sp, value: parseFloat(ed.air_travel) + parseFloat(ed.road_travel) + parseFloat(ed.rail_travel), icon: 'fas fa-car-side', color: '#EF4444' },
        { name: LABEL.hotel.sp, value: parseFloat(ed.hotel_stay), icon: 'fas fa-luggage-cart', color: '#F59E0B' },

        { name: LABEL.eventVenue.sp, value: parseFloat(ed.energy) + parseFloat(ed.event_material) + parseFloat(ed.waste) + parseFloat(ed.water), icon: 'fas fa-map-marker-alt', color: '#D946EF' },
        // { name: 'Consumable', value: parseFloat(ed.consumables), icon: 'fas fa-cookie-bite', color: '#3B82F6' },

        // { name: 'Beverage & Snacks', value: parseFloat(ed.beverage_and_snacks), icon: 'fas fa-coffee', color: '#D946EF' },
    ].sort(dynamicSort('-value'));

    return (
        <>
            <div className="container-fluid m-3 ps-0">

                <div className="">
                    <PDFPreview />
                    <div className="card mb-4">
                        <div className="card-header bg-light border-bottom">
                            <h6 className="mb-0">{LABEL.estimatedEmission.sp} (kgCO2e)</h6>
                        </div>
                        <div className="card-body pb-0">
                            <div className="">
                                <div className="mb-3">
                                    <div className="position-relative">
                                        <PieChart data={categories} showLegend={true} total={getFormattedNumber(total_emission)} />
                                    </div>
                                </div>
                                <div className="">
                                    <hr className="mx-nx1 mb-0 d-md-none d-xxl-block" />
                                    {
                                        categories.map((item: any, index: number) => {
                                            const percentage_of_total = (item.value / total_emission) * 100;
                                            return <>
                                                <div className="d-flex row flex-between-center border-bottom py-3 pt-md-0 pt-xxl-3" key={index}>
                                                    <div className="col-4 d-flex">
                                                        <span style={{ color: item.color }} className={`${item.icon} me-3`} />
                                                        <h6 className="text-700 mb-0">{item.name}</h6>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="fs--1 text-500 text-end mb-0 fw-semi-bold">{getFormattedFloat(item.value)}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h6 className="text-700 text-end mb-0">{item.value !== 0 ? `${getFormattedFloat(percentage_of_total)}%` : '-'}</h6>
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="d-grid">
                        <button type="button" onClick={donwloadPDF} className="btn btn-lg btn-primary">{LABEL.downloadCertificate.sp}</button>
                    </div>

                </div>

            </div>
            <Navigation />
        </>
    )

    async function donwloadPDF() {
        const visitor_detail = getStorage(constant.key.eventData);
        const fontURL = '/assets/event/fonts/Poppins-Bold.ttf'
        const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer())

        const pdfURL = `/assets/event/pdf/event-2-version-2.pdf`;
        const existingPdfBytes = await fetch(pdfURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(fontBytes)

        const pages = pdfDoc.getPages();

        //#region page 1
        const { height, width } = pages[0].getSize()
        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 516,
                y: height - 356,
                size: 28,
                color: rgb(0.00784313725490196, 0.3568627450980392, 0.25098039215686274),
                // 025b40
                font: customFont,
            });

        pages[0].drawText(
            getFormattedNumber(total_emission) + "",
            {
                // x: width - 430,
                x: width - 242,
                y: height - 390,
                size: 14,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 378,
                y: height - 410,
                size: 14,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        const pdfBytes = await pdfDoc.save();

        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });

        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = `certificado-de-compensación-de-carbono-${moment().format('DD-MMM-YYYY')}.pdf`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();

        const uid = getStorage(constant.key.eventUID);
        addDownloadDetail2({ uid })
    }
}

function PDFPreview() {
    const [docURL, setDocURL] = useState(null);
    const detail = getStorage(constant.key.eventData);
    const total_emission = Math.round(detail.total_emission);

    useEffect(() => {
        donwloadPDF();
    }, [])

    return (
        <>
            {
                docURL && <iframe title="Heter Iska" style={{ width: '100%', height: '100vh' }} src={docURL} />
            }
        </>
    )

    async function donwloadPDF() {
        const visitor_detail = getStorage(constant.key.eventData);
        const fontURL = '/assets/event/fonts/Poppins-Bold.ttf'
        const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer())

        const pdfURL = `/assets/event/pdf/event-2-version-2.pdf`;
        const existingPdfBytes = await fetch(pdfURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(fontBytes)

        const pages = pdfDoc.getPages();

        //#region page 1
        const { height, width } = pages[0].getSize()

        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 516,
                y: height - 356,
                size: 28,
                color: rgb(0.00784313725490196, 0.3568627450980392, 0.25098039215686274),
                // 025b40
                font: customFont,
            });

        pages[0].drawText(
            getFormattedNumber(total_emission) + "",
            {
                // x: width - 430,
                x: width - 242,
                y: height - 390,
                size: 14,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 378,
                y: height - 410,
                size: 14,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        const pdfBytes = await pdfDoc.save();

        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });

        // const link = document.createElement('a');
        // // create a blobURI pointing to our Blob
        // link.href = URL.createObjectURL(blob);
        // link.download = `carbon-offset-certificate-${moment().format('DD-MMM-YYYY')}.pdf`;
        // // some browser needs the anchor to be in the doc
        // document.body.append(link);
        // link.click();
        // link.remove();

        const _docURL: any = URL.createObjectURL(blob);
        setDocURL(_docURL);
        // const uid = getStorage(constant.key.eventUID);
        // addDownloadDetail2({ uid })
    }
}

export default Report;
