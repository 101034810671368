import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import uniqid from 'uniqid';

import constant from "../../constant";
import { addDetail2 } from "../../action/VisitorDetailAction";
import { checkVersion, getStorage, setStorage } from "../../utils";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../context/ToastContext";
import Loader from "../../component/common/Loader";
import Navigation from "../../component/event/Navigation-2";
// https://www.freecodecamp.org/news/how-to-create-forms-in-react-using-react-hook-form/

const LABEL = {
    participantDetails: { en: 'Participant Details', sp: 'Detalles de participantes' },
    name: { en: 'Name', sp: 'Nombre y apellido' },
    email: { en: 'Email id', sp: 'Email:' },
    mobile: { en: 'Mobile no', sp: 'Número de teléfono' },
    travelData: { en: 'Travel data', sp: 'Información de viaje' },
    travelDataNote: {
        en: 'Note : Please consider total to & fro distance from origin to venue',
        sp: 'por favor, considere la distancia total desde y hacia el lugar de origen'
    },
    roadTravel: {
        en: 'To & Fro Road travel - kms',
        sp: 'Ida y vuelta en transporte automotor (kms)'
    },
    trainTravel: {
        en: 'To & Fro Train/metro Travel - kms',
        sp: 'Ida y vuelta en tren/subte (kms)'
    },
    airTravel: {
        en: 'To & Fro Air Travel - kms',
        sp: 'Ida y vuelta en avión (kms)'
    },
    hotelStay: {
        en: 'Stay',
        sp: 'Estancia'
    },
    noOfNights: {
        en: 'No of Nights Hotel Stay',
        sp: 'Número de noches de hotel'
    },
    submit: {
        en: 'Submit', sp: 'Enviar'
    },
    thankYouForParticipation: {
        en: 'Thanks for your participation in carbon neutral event',
        sp: 'Gracias por su participación en el evento carbono neutral'
    },
    form: {
        thisFieldIsRequired: 'Este campo es obligatorio',
        emailIsNotInCorrectFormat: 'El correo electrónico no tiene el formato correcto',
        noMustBeOf10Digits: 'El número debe tener 10 dígitos',
        onlyNumbersAreAllowed: 'Sólo se permiten números'
    }
}

const EMISSION_FACTOR = {
    roadTravel: 0.169826,
    railTravel: 0.035463,
    airTravel: 0.18592,
    hotelStay: 56,

    water: 1.767,
    energy: 0.039296,
    waste: 0.0014 + 0.00176 + 0.0012,
    catering: 2.54 + 0.3,
    eventMaterial: 0.0048 + 0.0002
}

// const MEAL_PREFERENCE = { veg: 1, non_veg: 2 }
function Home() {
    const { toast }: any = useContext(ToastContext);
    const [showLoader, setShowLoader] = useState(false);
    const [uid, setUID]: any = useState(uniqid());

    let history = useHistory();

    useEffect(() => {
        checkVersion();

        document.title = "Evento Carbono Neutral - EKI";
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const storageUID = getStorage(constant.key.eventUID);
        if (storageUID) {
            setUID(storageUID);
        } else {
            setStorage(constant.key.eventUID, uid);
        }
    }, []);

    const { register, reset, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = (data: any) => {
        //#region calculating emission
        const road_travel = parseFloat(data.road_travel ? data.road_travel : '0');
        const rail_travel = parseFloat(data.rail_travel ? data.rail_travel : '0');
        const air_travel = parseFloat(data.air_travel ? data.air_travel : '0');
        const hotel_stay = parseFloat(data.hotel_stay ? data.hotel_stay : '0');

        const energy = parseFloat('1');
        const water = parseFloat('1');
        const waste = parseFloat('1');
        const catering = parseFloat('1');
        const event_material = parseFloat('1');

        const input_json = {
            road_travel,
            rail_travel,
            air_travel,

            hotel_stay,

            energy,
            waste,
            water,
            catering,
            event_material,
        }
        const emission_json = {
            road_travel: road_travel * EMISSION_FACTOR.roadTravel,
            rail_travel: rail_travel * EMISSION_FACTOR.railTravel,
            air_travel: air_travel * EMISSION_FACTOR.airTravel,
            hotel_stay: hotel_stay * EMISSION_FACTOR.hotelStay,

            energy: energy * EMISSION_FACTOR.energy,
            waste: waste * EMISSION_FACTOR.waste,
            water: water * EMISSION_FACTOR.water,
            catering: catering * EMISSION_FACTOR.catering,
            event_material: event_material * EMISSION_FACTOR.eventMaterial,
        }

        const total_emission = emission_json.road_travel + emission_json.rail_travel + emission_json.air_travel
            + emission_json.hotel_stay + emission_json.energy + emission_json.waste
            + emission_json.water + emission_json.catering + emission_json.event_material;
        //#endregion

        data.uid = uid;
        data.input_json = input_json;
        data.emission_json = emission_json;
        data.total_emission = total_emission;
        // data.veg_meal = mealPreference && mealPreference === MEAL_PREFERENCE.veg ? 1 : 0;
        // data.non_veg_meal = mealPreference && mealPreference === MEAL_PREFERENCE.non_veg ? 1 : 0;

        setShowLoader(true);
        addDetail2(data).then((res: any) => {
            setShowLoader(false);
            if (res.error) {
                toast.success(res.error);
            } else {
                setStorage(constant.key.eventData, res.result);
                reset();
                toast.success(LABEL.thankYouForParticipation.sp, { autoClose: 2000 });
                history.push(constant.component.event2Report.url);
            }
        })
    }

    return (
        <>
            {showLoader && <Loader />}
            <div className="container-fluid m-3 ps-0">
                <div >

                    <div className="text-center mb-4">
                        <img src="/assets/event/img/event-2-banner-1.jpg?i=1" className="w-100" style={{ borderRadius: '0.3rem' }} alt="banner" />
                    </div>

                    <div className="mb-4">
                        {UserDetail()}
                    </div>
                    <div className="mb-4">
                        {TravelDetail()}
                    </div>
                    <div className="mb-4">
                        {HotelDetail()}
                    </div>

                    <div className="d-grid">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="btn btn-lg btn-primary">{LABEL.submit.sp}</button>
                    </div>
                </div>
            </div>
            <Navigation />
        </>
    )

    function UserDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">{LABEL.participantDetails.sp}</h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <div className="">
                            <div className="mb-3">
                                <label className="form-label">{LABEL.name.sp} *</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="far fa-user-circle" />
                                    </span>
                                    <input
                                        type="text"
                                        className={errors.name ? "form-control is-invalid" : "form-control"}
                                        {...register("name", { required: true })}
                                    />
                                </div>
                                {errors.name && <span>{LABEL.form.thisFieldIsRequired}</span>}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">{LABEL.email.sp} *</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fas fa-envelope" />
                                    </span>
                                    <input
                                        type="email"
                                        className={errors.email ? "form-control is-invalid" : "form-control"}
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                        })}
                                    />
                                </div>
                                {errors.email?.type === 'required' && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                {errors.email?.type === 'pattern' && <span>{LABEL.form.emailIsNotInCorrectFormat}</span>}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">{LABEL.mobile.sp} *</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fas fa-phone" />
                                    </span>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className={errors.mobile ? "form-control is-invalid" : "form-control"}
                                        {...register("mobile", {
                                            required: true,
                                            pattern: /^[0-9]{10}$/,
                                            minLength: {
                                                value: 10,
                                                message: "Too Less Characters",
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Too Many Characters",
                                            },
                                        })}
                                    />
                                </div>
                                {errors.mobile?.type === "required" && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                {errors.mobile?.type === "minLength" && <span>{LABEL.form.noMustBeOf10Digits}</span>}
                                {errors.mobile?.type === "maxLength" && <span>{LABEL.form.noMustBeOf10Digits}</span>}
                                {errors.mobile?.type === "pattern" && <span>{LABEL.form.onlyNumbersAreAllowed}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function TravelDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">{LABEL.travelData.sp}
                        </h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <p>
                            {LABEL.travelDataNote.sp}
                        </p>
                        <form>
                            <div className="">
                                <div className="mb-3">
                                    <label className="form-label">{LABEL.roadTravel.sp}</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fas fa-car" />
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.road_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("road_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.road_travel && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{LABEL.trainTravel.sp}</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fas fa-train" />
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.rail_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("rail_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.rail_travel && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{LABEL.airTravel.sp}</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fas fa-plane" />
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.air_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("air_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.air_travel && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }

    function HotelDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">{LABEL.hotelStay.sp}</h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <form>
                            <div className="">
                                <div className="mb-3">
                                    <label className="form-label">{LABEL.noOfNights.sp}</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fas fa-bed" />
                                        </span>
                                        <input
                                            type="number"
                                            min={0}
                                            className={errors.hotel_stay ? "form-control is-invalid" : "form-control"}
                                            {...register("hotel_stay", { required: false })}
                                        />
                                    </div>
                                    {errors.hotel_stay && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                </div>
                                {/* <div className="">
                                    <label className="form-label">Meal Preference</label>
                                    <div className="">
                                        <div className="form-check">
                                            <input
                                                style={{ transform: "scale(1.2)" }}
                                                onChange={() => setMealPreference(MEAL_PREFERENCE.veg)} className="form-check-input" type="radio" name="meal-preference" id="veg-id" value={MEAL_PREFERENCE.veg} checked={mealPreference === MEAL_PREFERENCE.veg} />
                                            <label className="form-label" htmlFor="veg-id">
                                                <i className="fas fa-seedling" style={{ height: 18, width: 18 }} /> Vegetarian</label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                style={{ transform: "scale(1.2)" }}
                                                onChange={() => setMealPreference(MEAL_PREFERENCE.non_veg)} className="form-check-input" type="radio" name="meal-preference" id="non-veg-id" value={MEAL_PREFERENCE.non_veg} checked={mealPreference === MEAL_PREFERENCE.non_veg} />
                                            <label className="form-label" htmlFor="non-veg-id">
                                                <i className="fas fa-drumstick-bite" style={{ height: 18, width: 18 }} /> Non Vegetarian</label>
                                        </div>
                                    </div>
                                </div> */}
                                {/* {
                                    mealPreference === MEAL_PREFERENCE.veg && <div className="mb-3">
                                        <label className="form-label">Veg Meal (Total Meals)</label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fas fa-seedling" />
                                            </span>
                                            <input
                                                type="number"
                                                className={errors.veg_meal ? "form-control is-invalid" : "form-control"}
                                                {...register("veg_meal", { required: false })}
                                            />
                                        </div>
                                        {errors.veg_meal && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                    </div>
                                }
                                {
                                    mealPreference === MEAL_PREFERENCE.non_veg && <div className="mb-3">
                                        <label className="form-label">Non-Veg Meal (Total Meals)</label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fas fa-drumstick-bite" />
                                            </span>
                                            <input
                                                type="number"
                                                className={errors.non_veg_meal ? "form-control is-invalid" : "form-control"}
                                                {...register("non_veg_meal", { required: false })}
                                            />
                                        </div>
                                        {errors.non_veg_meal && <span>{LABEL.form.thisFieldIsRequired}</span>}
                                    </div>
                                } */}
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;