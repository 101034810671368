const hexToRgb: any = (hexValue: any) => {
    var hex;
    hexValue.indexOf('#') === 0 ? hex = hexValue.substring(1) : hex = hexValue; // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")

    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
        return r + r + g + g + b + b;
    }));
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}
const getPosition: any = (pos: any, params: any, dom: any, rect: any, size: any) => {
    return {
        top: pos[1] - size.contentSize[1] - 10,
        left: pos[0] - size.contentSize[0] / 2
    };
};
const utils: any = {
    grays: {
        white: '#fff',
        100: '#f9fafd',
        200: '#edf2f9',
        300: '#d8e2ef',
        400: '#b6c1d2',
        500: '#9da9bb',
        600: '#748194',
        700: '#5e6e82',
        800: '#4d5969',
        900: '#344050',
        1000: '#232e3c',
        1100: '#0b1727',
        black: '#000'
    },
    colors: {
        primary: '#2c7be5',
        secondary: '#748194',
        success: '#00d27a',
        info: '#27bcfd',
        warning: '#f5803e',
        danger: '#e63757',
        light: '#f9fafd',
        dark: '#0b1727'
    },

    rgbaColor: (color: any, alpha: any) => {
        if (color === void 0) {
            color = '#fff';
        }

        if (alpha === void 0) {
            alpha = 0.5;
        }

        return "rgba(" + hexToRgb(color) + ", " + alpha + ")";
    },

    getColor: function (name: any, dom?: any) {
        var dom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.documentElement;
        return getComputedStyle(dom).getPropertyValue("--falcon-".concat(name)).trim();
    },

    getColors: (dom: any) => {
        return {
            primary: utils.getColor('primary', dom),
            secondary: utils.getColor('secondary', dom),
            success: utils.getColor('success', dom),
            info: utils.getColor('info', dom),
            warning: utils.getColor('warning', dom),
            danger: utils.getColor('danger', dom),
            light: utils.getColor('light', dom),
            dark: utils.getColor('dark', dom)
        };
    },

    getSoftColors: (dom: any) => {
        return {
            primary: utils.getColor('soft-primary', dom),
            secondary: utils.getColor('soft-secondary', dom),
            success: utils.getColor('soft-success', dom),
            info: utils.getColor('soft-info', dom),
            warning: utils.getColor('soft-warning', dom),
            danger: utils.getColor('soft-danger', dom),
            light: utils.getColor('soft-light', dom),
            dark: utils.getColor('soft-dark', dom)
        };
    },

    getGrays: (dom: any) => {
        return {
            white: utils.getColor('gray-white', dom),
            100: utils.getColor('gray-100', dom),
            200: utils.getColor('gray-200', dom),
            300: utils.getColor('gray-300', dom),
            400: utils.getColor('gray-400', dom),
            500: utils.getColor('gray-500', dom),
            600: utils.getColor('gray-600', dom),
            700: utils.getColor('gray-700', dom),
            800: utils.getColor('gray-800', dom),
            900: utils.getColor('gray-900', dom),
            1000: utils.getColor('gray-1000', dom),
            1100: utils.getColor('gray-1100', dom),
            black: utils.getColor('gray-black', dom)
        };
    },

    getFormatter(params: any) {
        return params.map(function (_ref16: any, index: any) {
            var value = _ref16.value,
                borderColor = _ref16.borderColor;
            return "<span class= \"fas fa-circle\" style=\"color: ".concat(borderColor, "\"></span>\n    <span class='text-600'>").concat((params[0].axisValue).format('MMM-YY'), ": ").concat(value, "</span>");
        }).join('<br/>');
    }
}

export default { hexToRgb, getPosition, utils }