function App() {
    document.title = '404 - Page Not Found';
    const onGoBack = () => {
        window.history.back();
    }
    return (
        <>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card" style={{ padding: '10rem' }}>
                            <h1 className="font-weight-bold">404</h1>
                            <p>The page you are looking for cannot be found. Click <button className="btn btn-link p-0" onClick={() => onGoBack()}>here</button> to go back</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default App;
