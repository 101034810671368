import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import uniqid from 'uniqid';

import constant from "../../constant";
import { addDetail } from "../../action/VisitorDetailAction";
import { checkVersion, getStorage, setStorage } from "../../utils";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../context/ToastContext";
import Loader from "../../component/common/Loader";
import Navigation from "../../component/event/Navigation";
// https://www.freecodecamp.org/news/how-to-create-forms-in-react-using-react-hook-form/

const EMISSION_FACTOR = {
    roadTravel: 0.166638587919463,
    railTravel: 0.028603,
    airTravel: 0.39044,
    hotelStay: 42.4,
    energy: 6,
    waste: 2,
    water: 2,
    consumables: 4,
    foodVeg: 5.7,
    foodNonVeg: 6.93,
    beverageAndSnacks: 6,
}

const MEAL_PREFERENCE = { veg: 1, non_veg: 2 }
function Home() {
    const { toast }: any = useContext(ToastContext);
    const [showLoader, setShowLoader] = useState(false);
    const [mealPreference, setMealPreference]: any = useState(null);
    const [uid, setUID]: any = useState(uniqid());

    let history = useHistory();

    useEffect(() => {
        checkVersion();

        document.title = 'Carbon Neutral Event - EKI';
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const storageUID = getStorage(constant.key.eventUID);
        if (storageUID) {
            setUID(storageUID);
        } else {
            setStorage(constant.key.eventUID, uid);
        }
    }, []);

    const { register, reset, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = (data: any) => {
        //#region calculating emission
        const road_travel = parseFloat(data.road_travel ? data.road_travel : '0') * EMISSION_FACTOR.roadTravel;
        const rail_travel = parseFloat(data.rail_travel ? data.rail_travel : '0') * EMISSION_FACTOR.railTravel;
        const air_travel = parseFloat(data.air_travel ? data.air_travel : '0') * EMISSION_FACTOR.airTravel;

        const hotel_stay = parseFloat(data.hotel_stay ? data.hotel_stay : '0') * EMISSION_FACTOR.hotelStay;
        const food_veg = parseFloat(mealPreference && mealPreference === MEAL_PREFERENCE.veg ? '1' : '0') * EMISSION_FACTOR.foodVeg;
        const food_non_veg = parseFloat(mealPreference && mealPreference === MEAL_PREFERENCE.non_veg ? '1' : '0') * EMISSION_FACTOR.foodNonVeg;

        const energy = parseFloat('1') * EMISSION_FACTOR.energy;
        const waste = parseFloat('1') * EMISSION_FACTOR.waste;
        const water = parseFloat('1') * EMISSION_FACTOR.water;
        const consumables = parseFloat('1') * EMISSION_FACTOR.consumables;
        const beverage_and_snacks = parseFloat('1') * EMISSION_FACTOR.beverageAndSnacks;

        const emission_detail_json = {
            road_travel,
            rail_travel,
            air_travel,

            hotel_stay,
            food_veg,
            food_non_veg,

            energy,
            waste,
            water,
            consumables,
            beverage_and_snacks,
            total: road_travel + rail_travel + air_travel + hotel_stay + food_veg + food_non_veg + energy + waste + water + consumables + beverage_and_snacks
        }
        //#endregion

        data.uid = uid;
        data.emission_detail_json = emission_detail_json;
        data.total_emission = emission_detail_json.total;
        data.veg_meal = mealPreference && mealPreference === MEAL_PREFERENCE.veg ? 1 : 0;
        data.non_veg_meal = mealPreference && mealPreference === MEAL_PREFERENCE.non_veg ? 1 : 0;

        setShowLoader(true);
        addDetail(data).then((res: any) => {
            setShowLoader(false);
            if (res.error) {
                toast.success(res.error);
            } else {
                setStorage(constant.key.eventData, res.result);
                reset();
                toast.success("Thanks for your participation in carbon neutral event", { autoClose: 2000 });
                history.push(constant.component.eventReport.url);
            }
        })
    }

    return (
        <>
            {showLoader && <Loader />}
            <div className="mb-3">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="text-center mb-4">
                        <img src="/assets/event/img/banner.jpg?i=5" className="w-100" style={{ borderRadius: '0.3rem' }} alt="banner" />
                    </div>

                    <div className="mb-4 px-3">
                        {UserDetail()}
                    </div>
                    <div className="mb-4 px-3">
                        {TravelDetail()}
                    </div>
                    <div className="mb-4 px-3">
                        {HotelDetail()}
                    </div>

                    <div className="d-grid px-3">
                        <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                    </div>
                </form>
            </div>
            <Navigation />
        </>
    )

    function UserDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">User Detail</h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <div className="">
                            <div className="mb-3">
                                <label className="form-label">Name <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        {/* <i className="far fa-user-circle" /> */}
                                        {/* <i className="ti ti-user-circle"></i> */}
                                        <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" /></svg>
                                    </span>
                                    <input
                                        type="text"
                                        className={errors.name ? "form-control is-invalid" : "form-control"}
                                        {...register("name", { required: true })}
                                    />
                                </div>
                                {errors.name && <span>This field is required</span>}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        {/* <i className="fas fa-envelope" /> */}
                                        {/* <i className="ti ti-mail"></i> */}

                                        <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                                    </span>
                                    <input
                                        type="email"
                                        className={errors.email ? "form-control is-invalid" : "form-control"}
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                        })}
                                    />
                                </div>
                                {errors.email?.type === 'required' && <span>This field is required</span>}
                                {errors.email?.type === 'pattern' && <span>Email is not in correct format</span>}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Mobile</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        {/* <i className="fas fa-phone" /> */}
                                        {/* <i className="ti ti-phone"></i> */}

                                        <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                                    </span>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className={errors.mobile ? "form-control is-invalid" : "form-control"}
                                    // {...register("mobile", {
                                    //     required: true,
                                    //     pattern: /^[0-9]{10}$/,
                                    //     minLength: {
                                    //         value: 10,
                                    //         message: "Too Less Characters",
                                    //     },
                                    //     maxLength: {
                                    //         value: 10,
                                    //         message: "Too Many Characters",
                                    //     },
                                    // })}
                                    />
                                </div>
                                {errors.mobile?.type === "required" && <span>This field is required</span>}
                                {errors.mobile?.type === "minLength" && <span>Number must be of 10 digits</span>}
                                {errors.mobile?.type === "maxLength" && <span>Number must be of 10 digits</span>}
                                {errors.mobile?.type === "pattern" && <span>Only numbers are allowed</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function TravelDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">Travel Detail</h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <p>
                            Note: Please consider total to & fro distance from origin to venue
                        </p>
                        <form>
                            <div className="">
                                <div className="mb-3">
                                    <label className="form-label">Road Travel (To & Fro in km)</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            {/* <i className="fas fa-car" /> */}
                                            {/* <i className="ti ti-car"></i> */}
                                            <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.road_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("road_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.road_travel && <span>This field is required</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Metro Travel (To & Fro in km)</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            {/* <i className="fas fa-train" /> */}
                                            {/* <i className="ti ti-train"></i> */}
                                            <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V352c0 48 35.2 87.7 81.1 94.9l-46 46C28.1 499.9 33.1 512 43 512H82.7c8.5 0 16.6-3.4 22.6-9.4L160 448H288l54.6 54.6c6 6 14.1 9.4 22.6 9.4H405c10 0 15-12.1 7.9-19.1l-46-46c46-7.1 81.1-46.9 81.1-94.9V96c0-53-43-96-96-96H96zM64 128c0-17.7 14.3-32 32-32h80c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM272 96h80c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H272c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32zM64 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm288-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.rail_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("rail_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.rail_travel && <span>This field is required</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Air Travel (To & Fro in km)</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            {/* <i className="fas fa-plane" /> */}
                                            {/* <i className="ti ti-plane"></i> */}

                                            <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64l-116.6 0L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1l-56.2 0c-10.6 0-18.3-10.2-15.4-20.4l49-171.6L112 320 68.8 377.6c-3 4-7.8 6.4-12.8 6.4l-42 0c-7.8 0-14-6.3-14-14c0-1.3 .2-2.6 .5-3.9L32 256 .5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14l42 0c5 0 9.8 2.4 12.8 6.4L112 192l102.9 0-49-171.6C162.9 10.2 170.6 0 181.2 0l56.2 0c11.5 0 22.1 6.2 27.8 16.1L365.7 192l116.6 0z" /></svg>
                                        </span>
                                        <input
                                            min={0}
                                            type="number"
                                            className={errors.air_travel ? "form-control is-invalid" : "form-control"}
                                            {...register("air_travel", { required: false })}
                                        />
                                    </div>
                                    {errors.air_travel && <span>This field is required</span>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }

    function HotelDetail() {
        return (
            <>
                <div className="card mb-3">
                    <div className="card-header bg-light border-bottom">
                        <h5 className="mb-0">Hotel/Food Detail</h5>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <form>
                            <div className="">
                                <div className="mb-3">
                                    <label className="form-label">Hotel Stay (No of nights)</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            {/* <i className="fas fa-bed" /> */}
                                            {/* <i className="ti ti-bed"></i> */}
                                            <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg>
                                        </span>
                                        <input
                                            type="number"
                                            min={0}
                                            className={errors.hotel_stay ? "form-control is-invalid" : "form-control"}
                                            {...register("hotel_stay", { required: false })}
                                        />
                                    </div>
                                    {errors.hotel_stay && <span>This field is required</span>}
                                </div>
                                <div className="">
                                    <label className="form-label">Meal Preference</label>
                                    <div className="">
                                        <div className="form-check">
                                            <input
                                                style={{ transform: "scale(1.2)" }}
                                                onChange={() => setMealPreference(MEAL_PREFERENCE.veg)} className="form-check-input" type="radio" name="meal-preference" id="veg-id" value={MEAL_PREFERENCE.veg} checked={mealPreference === MEAL_PREFERENCE.veg} />
                                            <label className="form-label" htmlFor="veg-id">
                                                <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                {/* <i className="fas fa-seedling" style={{ height: 18, width: 18 }} /> */}
                                                Vegetarian
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                style={{ transform: "scale(1.2)" }}
                                                onChange={() => setMealPreference(MEAL_PREFERENCE.non_veg)} className="form-check-input" type="radio" name="meal-preference" id="non-veg-id" value={MEAL_PREFERENCE.non_veg} checked={mealPreference === MEAL_PREFERENCE.non_veg} />
                                            <label className="form-label" htmlFor="non-veg-id">
                                                <svg className="me-1" style={{ height: 18, width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 265.2c0 8.5-3.4 16.6-9.4 22.6l-26.8 26.8c-12.3 12.3-32.5 11.4-49.4 7.2C69.8 320.6 65 320 60 320c-33.1 0-60 26.9-60 60s26.9 60 60 60c6.3 0 12 5.7 12 12c0 33.1 26.9 60 60 60s60-26.9 60-60c0-5-.6-9.8-1.8-14.5c-4.2-16.9-5.2-37.1 7.2-49.4l26.8-26.8c6-6 14.1-9.4 22.6-9.4H336c6.3 0 12.4-.3 18.5-1c11.9-1.2 16.4-15.5 10.8-26c-8.5-15.8-13.3-33.8-13.3-53c0-61.9 50.1-112 112-112c8 0 15.7 .8 23.2 2.4c11.7 2.5 24.1-5.9 22-17.6C494.5 62.5 422.5 0 336 0C238.8 0 160 78.8 160 176v89.2z" /></svg>
                                                Non Vegetarian
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    mealPreference === MEAL_PREFERENCE.veg && <div className="mb-3">
                                        <label className="form-label">Veg Meal (Total Meals)</label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fas fa-seedling" />
                                            </span>
                                            <input
                                                type="number"
                                                className={errors.veg_meal ? "form-control is-invalid" : "form-control"}
                                                {...register("veg_meal", { required: false })}
                                            />
                                        </div>
                                        {errors.veg_meal && <span>This field is required</span>}
                                    </div>
                                }
                                {
                                    mealPreference === MEAL_PREFERENCE.non_veg && <div className="mb-3">
                                        <label className="form-label">Non-Veg Meal (Total Meals)</label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fas fa-drumstick-bite" />
                                            </span>
                                            <input
                                                type="number"
                                                className={errors.non_veg_meal ? "form-control is-invalid" : "form-control"}
                                                {...register("non_veg_meal", { required: false })}
                                            />
                                        </div>
                                        {errors.non_veg_meal && <span>This field is required</span>}
                                    </div>
                                } */}
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;