import { Route, Switch } from 'react-router-dom';

import constant from './constant'
import { ToastProvider } from './context/ToastContext';

// misc
import Home from "./container/misc/Home";
import FourZeroFour from "./container/misc/FourZeroFour";
// misc

import EventHome from "./container/event/Home";
import EventReport from "./container/event/Report";

import Event2Home from "./container/event-2/Home";
import Event2Report from "./container/event-2/Report";

import ComingSoon from './container/misc/ComingSoon';


function Router() {
    return (
        <>
            <ToastProvider>
                <Switch>
                    <Route exact={true} path={constant.component.home.url} component={ComingSoon} />
                    <Route exact={true} path={constant.component.comingSoon.url} component={ComingSoon} />

                    <Route exact={true} path={constant.component.event.url} component={EventHome} />
                    <Route exact={true} path={constant.component.eventHome.url} component={EventHome} />
                    <Route exact={true} path={constant.component.eventReport.url} component={EventReport} />

                    <Route exact={true} path={constant.component.event2.url} component={Event2Home} />
                    <Route exact={true} path={constant.component.event2Home.url} component={Event2Home} />
                    <Route exact={true} path={constant.component.event2Report.url} component={Event2Report} />

                    <Route component={FourZeroFour} />
                </Switch>
            </ToastProvider>
        </>
    )
}

export default Router;
