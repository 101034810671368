import constant from "../../constant";

function ComingSoon() {
    document.title = constant.component.comingSoon.title;

    return (
        <>
            <div className="m-3">
                <div className="card">
                    <div className="card-body overflow-hidden p-lg-6">
                        <div className="col-lg-6 ps-lg-4 my-5 text-center text-lg-start">
                            <h4 className="text-primary mb-3">Coming Soon!!!</h4>
                            <p className="fs--1 mb-6">We are working on creating awesome feature and functionality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ComingSoon;