const app = { version: '1.0.0', currency: '₹' }

const keyPrefix = 'woce-event';
const key = {
    token: keyPrefix + '.token',
    version: keyPrefix + '.version',
    eventUID: keyPrefix + '.event-uid',
    eventData: keyPrefix + '.event-data',
}

const permission = {
    public: "0",
    captureStationaryEmission: "1",
    captureMobileSourcesEmission: "2",
    captureRefrigerationAndACEmission: "3",
    captureFireSupressionEmission: "4",
    capturePurchaseGasesEmission: "5",
    captureWasteGasesEmission: "6",
    captureElectricityEmission: "7",
    captureSteamEmission: "8",
    captureBusinessTravelEmission: "9",
    captureEmployeeCommutingEmission: "10",
    captureProductTransportEmission: "11",
    captureWasteEmission: "12",
    captureUpstreamEmission: "13",
    captureOperationalWasteEmission: "14",
    captureCapitalGoodsEmission: "15",
    capturePurchasedGoodsEmission: "16",
    captureWorkFromHomeEmission: "17",

    // View Permission
    viewStationaryEmission: "101",
    viewMobileSourcesEmission: "102",
    viewRefrigerationAndACEmission: "103",
    viewFireSupressionEmission: "104",
    viewPurchaseGasesEmission: "105",
    viewWasteGasesEmission: "106",
    viewElectricityEmission: "107",
    viewSteamEmission: "108",
    viewBusinessTravelEmission: "109",
    viewEmployeeCommutingEmission: "110",
    viewProductTransportEmission: "111",
    viewWasteEmission: "112",
    viewUpstreamEmission: "113",
    viewOperationalWasteEmission: "114",
    viewPurchasedGoodsEmission: "115",
    viewCapitalGoodsEmission: "116",
    viewWorkFromHomeEmission: "117",

    // Config Permission
    organizationEmissionDashboard: "201",
    organizationEmissionTrend: "202",
    organizationOffsetAnalytics: "203",
    organizationNetZeroTarget: "204",
    branchEmissionDashboard: "205",
    branchEmissionTrends: "206",
    branchNetZeroTarget: "207",
    approveCapturedEmissions: "208",
    manageUser: "209",
    manageRole: "210",
    manageVehicle: "211",
    manageWFHProfile: "212",
};

const component = {
    home: { url: '/', title: 'Home', permission: permission.public },
    comingSoon: { url: '/coming-soon', title: 'Coming Soon', permission: permission.public },

    // event
    event: { url: '/gfc-event', title: 'Carbon Neutral Event', permission: permission.public },
    eventHome: { url: '/gfc-event/home', title: 'Carbon Neutral Event', permission: permission.public },
    eventReport: { url: '/gfc-event/report', title: 'Report', permission: permission.public },

    event2: { url: '/event-2', title: 'Carbon Neutral Event', permission: permission.public },
    event2Home: { url: '/event-2/home', title: 'Carbon Neutral Event', permission: permission.public },
    event2Report: { url: '/event-2/report', title: 'Report', permission: permission.public },
    // event
};

const fieldType = {
    string: 'string',
    array: 'array',
    object: 'object',
    date: 'date',
    number: 'number',
}

const message = {
    recordAdded: 'Record was added successfully',
    recordUpdated: 'Record was saved successfully',
    recordDeleted: 'Record was deleted successfully',
    recordDuplicate: 'Record already exists',
    passwordChanged: 'Password was successfully updated',
};

const formatting = {
    dateFormat: 'DD MMM YYYY',
    timeFormat: 'hh:mm:ss A',
    dateTimeFormat: 'DD MMM YYYY hh:mm A',
    activityPeriod: 'MMM-YYYY',
    captureTimestamp: 'DD-MMM-YYYY hh:mm A'
};

const obj = { component, key, permission, fieldType, message, formatting, app, };

export default obj;