import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const ToastContext = React.createContext({});

export const ToastProvider = (props: any) => {
    return (
        <ToastContext.Provider value={{ toast }}>
            {props.children}
            <Toaster
                position="top-center"
            />
        </ToastContext.Provider>
    )
}

const WithToastContext = (Component: any) => {
    return (props: any) => (
        <ToastContext.Consumer>
            {(value: any) => <Component {...props} context={value} />}
        </ToastContext.Consumer>
    )
}

export default WithToastContext;