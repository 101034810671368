import axios from "axios";
import { getAuthHeader, getStorage } from "../utils";
import constant from "../constant";

// export const getDetail = (uid: number) => {
//   const url = `${process.env.REACT_APP_BASE_URL}/v1/visitor-detail/detail?uid=${uid}`;
//   return axios.get(url, getAuthHeader()).then((response: any) => {
//     return response.data;
//   });
// };

export const addDetail = (model: any) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/visitor-detail/add`;
  return axios.post(url, model).then((response: any) => {
    return response.data;
  });
};

export const addDownloadDetail = (model: any) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/visitor-detail/download-certificate`;
  return axios.post(url, model).then((response: any) => {
    return response.data;
  });
};

export const addDetail2 = (model: any) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/visitor-detail/add-2`;
  return axios.post(url, model).then((response: any) => {
    return response.data;
  });
};

export const addDownloadDetail2 = (model: any) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/visitor-detail/download-certificate-2`;
  return axios.post(url, model).then((response: any) => {
    return response.data;
  });
};