import { PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import moment from 'moment';

import { dynamicSort, getFormattedFloat, getFormattedNumber, getStorage } from '../../utils';
import { useHistory } from "react-router-dom";
import Navigation from '../../component/event/Navigation';
import constant from '../../constant';
import PieChart from '../../component/event/PieChart';
import { useEffect, useState } from 'react';
import { addDownloadDetail } from '../../action/VisitorDetailAction';
import Loader from '../../component/common/Loader';

function Report() {
    document.title = 'Report - EKI';
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const detail = getStorage(constant.key.eventData);
    let history = useHistory();

    if (!detail) {
        history.push(constant.component.eventHome.url);
    }

    const ed = JSON.parse(detail.emission_detail_json)
    const total_emission = Math.ceil(detail.total_emission);

    const categories = [
        { name: 'Travel', value: parseFloat(ed.air_travel) + parseFloat(ed.road_travel) + parseFloat(ed.rail_travel), icon: 'fas fa-car-side', color: '#EF4444' },
        { name: 'Hotel', value: parseFloat(ed.hotel_stay), icon: 'fas fa-luggage-cart', color: '#F59E0B' },
        { name: 'Energy', value: parseFloat(ed.energy), icon: 'fas fa-bolt', color: '#84CC16' },
        { name: 'Waste', value: parseFloat(ed.waste), icon: 'fas fa-recycle', color: '#10B981' },
        { name: 'Water', value: parseFloat(ed.water), icon: 'fas fa-tint', color: '#06B6D4' },
        { name: 'Consumable', value: parseFloat(ed.consumables), icon: 'fas fa-cookie-bite', color: '#3B82F6' },
        { name: 'Food', value: parseFloat(ed.food_veg) + parseFloat(ed.food_non_veg), icon: 'fas fa-utensils', color: '#8B5CF6' },
        { name: 'Beverage & Snacks', value: parseFloat(ed.beverage_and_snacks), icon: 'fas fa-coffee', color: '#D946EF' },
    ].sort(dynamicSort('-value'));

    return (
        <>
            {showLoader && <Loader />}
            <div className="container-fluid my-3">
                {/* <PDFPreview /> */}
                <div className="">
                    <div className="card mb-4">
                        <div className="card-header bg-light border-bottom">
                            <h6 className="mb-0">Estimated Emissions</h6>
                        </div>
                        <div className="card-body pb-0">
                            <div className="">
                                <div className="mb-3">
                                    <div className="position-relative">
                                        <PieChart data={categories} showLegend={true} total={getFormattedNumber(total_emission)} />
                                    </div>
                                </div>
                                <div className="d-none">
                                    <hr className="mx-nx1 mb-0 d-md-none d-xxl-block" />
                                    {
                                        categories.map((item: any, index: number) => {
                                            const percentage_of_total = (item.value / total_emission) * 100;
                                            return <>
                                                <div className="d-flex row flex-between-center border-bottom py-3 pt-md-0 pt-xxl-3" key={index}>
                                                    <div className="col-4 d-flex">
                                                        <span style={{ color: item.color }} className={`${item.icon} me-3`} />
                                                        <h6 className="text-700 mb-0">{item.name}</h6>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="fs--1 text-500 text-end mb-0 fw-semi-bold">{getFormattedFloat(item.value)}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h6 className="text-700 text-end mb-0">{item.value !== 0 ? `${getFormattedFloat(percentage_of_total)}%` : '-'}</h6>
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                </div>


                                <div className="card-body p-0">
                                    {
                                        categories.map((item: any, index: number) => {
                                            const percentage_of_total = (item.value / total_emission) * 100;
                                            return <>
                                                <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                                                    <div className="col ps-x1 py-1 position-static">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar avatar-xl me-3">
                                                                <div className="avatar-name rounded-circle" style={{ backgroundColor: item.color }}><span className="fs-9 text-white">{item.name.substring(0, 1)}</span></div>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h6 className="mb-0 d-flex align-items-center"><span className="text-800 stretched-link">{item.name}</span><span className="badge rounded-pill ms-2 bg-200 text-primary">{getFormattedNumber(percentage_of_total)}%</span></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col py-1 text-end">
                                                        <div className="fs-10 fw-semi-bold">{getFormattedFloat(item.value)}     <span className="text-muted fs--1">kgCO2e</span> </div>
                                                    </div>
                                                </div >
                                            </>
                                        })
                                    }
                                    <div className="d-none">
                                        <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                                            <div className="col ps-x1 py-1 position-static">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-xl me-3">
                                                        <div className="avatar-name rounded-circle bg-primary-subtle text-dark"><span className="fs-9 text-primary">F</span></div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="mb-0 d-flex align-items-center"><a className="text-800 stretched-link" href="#!">Falcon</a><span className="badge rounded-pill ms-2 bg-200 text-primary">38%</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col py-1">
                                                <div className="row flex-end-center g-0">
                                                    <div className="col-auto pe-2">
                                                        <div className="fs-10 fw-semi-bold">12:50:00</div>
                                                    </div>
                                                    <div className="col-5 pe-x1 ps-2">
                                                        {/* <div className="progress bg-200 me-2" style="height: 5px;" role="progressbar" aria-valuenow="38" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar rounded-pill" style="width: 38%"></div>
                          </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                                            <div className="col ps-x1 py-1 position-static">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-xl me-3">
                                                        <div className="avatar-name rounded-circle bg-success-subtle text-dark"><span className="fs-9 text-success">R</span></div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="mb-0 d-flex align-items-center"><a className="text-800 stretched-link" href="#!">Reign</a><span className="badge rounded-pill ms-2 bg-200 text-primary">79%</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col py-1">
                                                <div className="row flex-end-center g-0">
                                                    <div className="col-auto pe-2">
                                                        <div className="fs-10 fw-semi-bold">25:20:00</div>
                                                    </div>
                                                    <div className="col-5 pe-x1 ps-2">
                                                        {/* <div className="progress bg-200 me-2" style="height: 5px;" role="progressbar" aria-valuenow="79" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar rounded-pill" style="width: 79%"></div>
                          </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                                            <div className="col ps-x1 py-1 position-static">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-xl me-3">
                                                        <div className="avatar-name rounded-circle bg-info-subtle text-dark"><span className="fs-9 text-info">B</span></div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="mb-0 d-flex align-items-center"><a className="text-800 stretched-link" href="#!">Boots4</a><span className="badge rounded-pill ms-2 bg-200 text-primary">90%</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col py-1">
                                                <div className="row flex-end-center g-0">
                                                    <div className="col-auto pe-2">
                                                        <div className="fs-10 fw-semi-bold">58:20:00</div>
                                                    </div>
                                                    <div className="col-5 pe-x1 ps-2">
                                                        {/* <div className="progress bg-200 me-2" style="height: 5px;" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar rounded-pill" style="width: 90%"></div>
                          </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                                            <div className="col ps-x1 py-1 position-static">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-xl me-3">
                                                        <div className="avatar-name rounded-circle bg-warning-subtle text-dark"><span className="fs-9 text-warning">R</span></div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="mb-0 d-flex align-items-center"><a className="text-800 stretched-link" href="#!">Raven</a><span className="badge rounded-pill ms-2 bg-200 text-primary">40%</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col py-1">
                                                <div className="row flex-end-center g-0">
                                                    <div className="col-auto pe-2">
                                                        <div className="fs-10 fw-semi-bold">21:20:00</div>
                                                    </div>
                                                    <div className="col-5 pe-x1 ps-2">
                                                        {/* <div className="progress bg-200 me-2" style="height: 5px;" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar rounded-pill" style="width: 40%"></div>
                          </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center py-2 position-relative">
                                            <div className="col ps-x1 py-1 position-static">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-xl me-3">
                                                        <div className="avatar-name rounded-circle bg-danger-subtle text-dark"><span className="fs-9 text-danger">S</span></div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <h6 className="mb-0 d-flex align-items-center"><a className="text-800 stretched-link" href="#!">Slick</a><span className="badge rounded-pill ms-2 bg-200 text-primary">70%</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col py-1">
                                                <div className="row flex-end-center g-0">
                                                    <div className="col-auto pe-2">
                                                        <div className="fs-10 fw-semi-bold">31:20:00</div>
                                                    </div>
                                                    <div className="col-5 pe-x1 ps-2">
                                                        {/* <div className="progress bg-200 me-2" style="height: 5px;" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar rounded-pill" style="width: 70%"></div>
                          </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="d-grid">
                        <button type="button" onClick={donwloadPDF} className="btn btn-lg btn-primary">Download Certificate</button>
                    </div>

                </div>

            </div >
            <Navigation />
        </>
    )

    async function donwloadPDF() {
        setShowLoader(true);
        const visitor_detail = getStorage(constant.key.eventData);
        const fontURL = '/assets/event/fonts/Poppins-Bold.ttf'
        const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer())

        const pdfURL = `/assets/event/pdf/event-1.pdf`;
        const existingPdfBytes = await fetch(pdfURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(fontBytes)

        const pages = pdfDoc.getPages();

        //#region page 1
        const { height, width } = pages[0].getSize()
        // pages[0].drawRectangle({
        //     x: width - 800,
        //     y: height - 266,

        //     width: 638,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })


        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 668,
                y: height - 196,
                size: 12,
                color: rgb(0.00784313725490196, 0.3568627450980392, 0.25098039215686274),
                // 025b40
                font: customFont,
            });

        // pages[0].drawRectangle({
        //     x: width - 436,
        //     y: height - 294,

        //     width: 108,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })

        pages[0].drawText(
            getFormattedNumber(total_emission) + "",
            {
                // x: width - 430,
                x: width - 380,
                y: height - 220,
                size: 12,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        // pages[0].drawRectangle({
        //     x: width - 738,
        //     y: height - 333,

        //     width: 538,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })
        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 666,
                y: height - 240,
                size: 12,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });


        const pdfBytes = await pdfDoc.save();

        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });

        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = `carbon-offset-certificate-${moment().format('DD-MMM-YYYY')}.pdf`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();

        // const _docURL: any = URL.createObjectURL(blob);
        // setDocURL(_docURL);
        const uid = getStorage(constant.key.eventUID);
        addDownloadDetail({ uid })
        setShowLoader(false);
    }
}

function PDFPreview() {
    const [docURL, setDocURL] = useState(null);
    const detail = getStorage(constant.key.eventData);
    const total_emission = Math.round(detail.total_emission);

    useEffect(() => {
        donwloadPDF();
    }, [])

    return (
        <>
            {
                docURL && <iframe title="Heter Iska" style={{ width: '100%', height: '100vh' }} src={docURL} />
            }
        </>
    )

    async function donwloadPDF() {
        const visitor_detail = getStorage(constant.key.eventData);
        const fontURL = '/assets/event/fonts/Poppins-Bold.ttf'
        const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer())

        const pdfURL = `/assets/event/pdf/event-1/certificate.pdf`;
        const existingPdfBytes = await fetch(pdfURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(fontBytes)

        const pages = pdfDoc.getPages();

        //#region page 1
        const { height, width } = pages[0].getSize()
        // pages[0].drawRectangle({
        //     x: width - 800,
        //     y: height - 266,

        //     width: 638,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })


        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 668,
                y: height - 196,
                size: 12,
                color: rgb(0.00784313725490196, 0.3568627450980392, 0.25098039215686274),
                // 025b40
                font: customFont,
            });

        // pages[0].drawRectangle({
        //     x: width - 436,
        //     y: height - 294,

        //     width: 108,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })

        pages[0].drawText(
            getFormattedNumber(total_emission) + "",
            {
                // x: width - 430,
                x: width - 380,
                y: height - 220,
                size: 12,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        // pages[0].drawRectangle({
        //     x: width - 738,
        //     y: height - 333,

        //     width: 538,
        //     height: 20,
        //     borderWidth: 0,
        //     color: rgb(1, 1, 1),
        //     opacity: 1,
        // })
        pages[0].drawText(
            visitor_detail.name,
            {
                x: width - 666,
                y: height - 240,
                size: 12,
                color: rgb(0.050980392156862744, 0.4392156862745098, 0.7254901960784313),
                // 025b40
                font: customFont,
            });

        const pdfBytes = await pdfDoc.save();

        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });

        const _docURL: any = URL.createObjectURL(blob);
        setDocURL(_docURL);
        // const uid = getStorage(constant.key.eventUID);
        // addDownloadDetail2({ uid })
    }
}

export default Report;