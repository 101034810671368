import { NavLink } from "react-router-dom";
import constant from "../../constant";
import { getStorage } from "../../utils";

function Navigation() {
    const detail = getStorage(constant.key.eventData);
    if (detail === null || detail === undefined) {
        return null;
    }
    const version = getStorage(constant.key.version)
    return (
        <>
            <div className="" style={{ marginBottom: '6rem' }}>
                &nbsp;
            </div>
            <div style={{
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                background: '#fff',
                padding: '1rem 0rem',
                borderTop: '1px solid #eee',
                zIndex: 3
            }}>
                <div>
                    <NavLink activeStyle={{ color: '#2c7be5' }} to={constant.component.event2Home.url} className="btn btn-falcon-default rounded-pill">
                        <i className="fas fa-edit"></i>
                    </NavLink>
                </div>
                <div>
                    <button className="btn btn-link p-0 text-white" onClick={() => alert(version)}>test</button>
                </div>
                <div>
                    <NavLink activeStyle={{ color: '#2c7be5' }} disabled={!detail} to={constant.component.event2Report.url} className="btn btn-falcon-default rounded-pill">
                        <i className="fas fa-chart-pie"></i>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default Navigation;